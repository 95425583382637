<template>
  <section class="section our-goal ptb_100">
    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div class="col-12 col-lg-6">
          <!-- Goal Content -->
          <div
            class="goal-content section-heading text-center text-lg-left pr-md-4 mb-0"
          >
            <h2 class="mb-3">Nuestro objetivo</h2>
            <p>
              Nuestra prioridad es hacer que la ayuda legal sea accesible para
              todos. Al brindar un consejo inteligente y amigable, nuestros
              experimentados abogados garantizan un procedimiento transparente.
            </p>
            <p>
              Mismo que puede monitorearse en tiempo real por el usuario. Con la
              garantía de que siempre se hará el máximo esfuerzo para lograr el
              éxito.
            </p>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <!-- Goal Thumb -->
          <div class="goal-thumb mt-5 mt-lg-0">
            <img src="assets/img/objetivo.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="container mt-5">
      <div class="row justify-content-between align-items-center">
        <div class="col-12 col-lg-8">
          <!-- Goal Thumb -->
          <div class="goal-thumb mt-5 mt-lg-0">
            <img src="assets/img/et-tarjeta.png" alt="" />
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <!-- Goal Content -->
          <div
            class="goal-content section-heading text-center text-lg-left pr-md-4 mb-0"
          >
            <h2 class="mb-3">Encuéntranos</h2>
            <p>
              
            </p>
            <p>
              
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  
</template>

<script>
export default {};
</script>

<style scoped>
p{
  font-size: 25px;
  text-align: justify;
}
</style>