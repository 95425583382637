<template>
    <div>
      <ScrollupSection />
      <div class="main overflow-hidden">
        <HeaderSection />
        <BreadcrumbSection />
        <FormatosChatET/>
        <AboutUsSection />
        <GoalSection />
        <!-- <TeamSection /> -->
        <ContactSection />
        <!-- <CtaSection /> -->
        <FooterSection />
        <ModalSearchSection />
        <ModalRegistro/>
        <ModalMenuSection />
      </div>
    </div>
  </template>
  
  <script>
  import FormatosChatET from '../../ChatET/FormatosChatET'
  import ScrollupSection from '../../Scrollup/Scrollup'
  import HeaderSection from '../../Header/HeaderOne'
  import BreadcrumbSection from '../../Breadcrumb/ChatET'

  //import GoalSection from '../../Goal/Goal'
  /* import TeamSection from '../../Team/TeamOne' */
  //import ContactSection from '../../Contact/ContactOne'
  // import CtaSection from '../../Cta/Cta'
  import FooterSection from '../../Footer/FooterOne'
  import ModalSearchSection from '../../Modal/ModalSearch/ModalSearch'
  import ModalRegistro from '../../Modal/ModalSearch/ModalRegistro';
  import ModalMenuSection from '../../Modal/ModalMenu/ModalMenu'
  
  export default {
    name: 'ChatET',
    metaInfo: {
      title:"Electoral Tech & Law | ChatET",
      description:"",
    },
    components: {
      ScrollupSection,
      HeaderSection,
      BreadcrumbSection,

      FormatosChatET,
      //GoalSection,
      //TeamSection,
      //ContactSection,
      //CtaSection,
      FooterSection,
      ModalSearchSection,
      ModalMenuSection,
      ModalRegistro
    }
  }
  </script>
  
  <style>
  
  </style>