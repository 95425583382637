<template>
    <section class="section breadcrumb-area overlay-dark d-flex align-items-center"
        :style="{ background: `rgba(0, 0, 0, 0) url(${ this.back }) no-repeat fixed center center / cover`} ">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <!-- Breamcrumb Content -->
                    <div class="breadcrumb-content d-flex flex-column align-items-center text-center">
                        <!--Esto es para tener distintos tamaños de mayus en ChatET-->
                        <h2 class="text-white text-uppercase mb-3">C<span style="font-size: smaller;">hat</span>ET</h2>
                        <h3 class="text-white-50">Utiliza C<span style="font-size:smaller;">HAT</span>ET para predecir la resolución de expedientes  que se encuentren activos, ingresa los datos clave y obtén tu predicción.</h3>
                        <!-- <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a class="text-uppercase text-white" href="/">Home</a></li>
                            <li class="breadcrumb-item"><a class="text-uppercase text-white" href="#">Pages</a></li>
                            <li class="breadcrumb-item text-white active">About Us</li>
                        </ol> -->
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name:'AboutBreadcrumb',
    data(){
        return {
            back: "../../assets/fondos/acercade.png",
        }
    }
}
</script>

<style>

</style>