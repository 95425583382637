<template>
  <section class="section about-area ptb_100">
    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div class="col-12 col-lg-6">
          <!-- About Thumb -->
          <div class="about-thumb text-center">
            <img src="assets/img/quienessomos.png" alt="" />
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <!-- About Content -->
          <div
            class="about-content section-heading text-center text-lg-left pl-md-4 mt-5 mt-lg-0 mb-0"
          >
            <h2 class="mb-3">¿Quiénes somos?</h2>
            <p>
              Electoral Tech & Legal es un sitio electrónico para el desarrollo
              en línea de productos “HAZLO TÚ MISMO” a partir del uso de la
              tecnología inteligente en materia electoral. 
            </p>
            <br>
            <p>
              Proporcionamos diversos servicios en línea
              que van desde el autoaprendizaje ( e-learning), los servicios
              legales electrónicos (legal marketplace) y el auto-llenado de
              demandas judiciales (e-filling).
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
import jsPDF from "jspdf";

export default {
  methods: {
    generatePDF2() {
      // Crear una instancia de jsPDF con tamaño de página 'letter'
      const pdf = new jsPDF('p', 'pt', 'letter');

      pdf.save("demanda.pdf");
      },
    },
  }
</script>













<style scoped>
p {
  font-size: 25px;
  text-align: justify;
}
</style>
